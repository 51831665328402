import React, {useEffect, useRef, useState} from 'react';
import {Header} from "src/components/Header";
import {ImportOrgModHeader} from "src/components/ImportOrgModHeader";
import MultipleChoice from "src/components/Chapter/MultipleChoice";
import {Box, Button, ColorPaletteProp, Divider, Input, Snackbar} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import QuestionsAndAnswers from "src/components/Chapter/QuestionsAndAnswers";
import ParagraphText from "src/components/Chapter/ParagraphText";
import {highlightInvalidField} from "src/utils/functions";
import {saveBookMod, useLazyGetChapterQuery, upsertChapter} from "src/utils/api-service";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {ModTypes, Paths, TextInputColor} from "src/utils/constants";
import {BulkInsertContainer} from "src/components/Shared/BulkInsertContainer";
import {useNavigate} from "react-router-dom";
import {IBookModChapterSchema, IBookModSchema} from "src/zodSchemas";
import {
    addChapter,
    resetChapterID,
    setCurrentChapterID,
    setCurrentParagraphID,
    updateChapter,
    deleteChapter, setShowBulkDataInsertedMsg, setBookID, cloneParagraphs
} from "src/slices/bookSlice";
import {setInputColor} from "src/utils/functions";
import {RichTreeView} from "@mui/x-tree-view";
import {TreeViewItem} from "src/utils/interfaces";
import {deleteConfirmationDialog} from "src/components/Shared/DeleteModal";
import {IBookModChapter} from "@backend/mongoose.gen";

export const ImportBook: React.FC = () => {
    const [importComplete, setImportComplete] = useState(false)
    const [saveAllInfoClicked, setSaveAllInfoClicked] = useState(false)
    const [snackbarMsg, setSnackbarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [snackbarMsgColor, setSnackbarMsgColor] = useState("neutral")
    const [titleInputColor, setTitleInputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [chaptersData, setChaptersData] = useState<TreeViewItem[]>([])
    const [expandedItems, setExpandedItems] = React.useState<string[]>([])
    const [chapterTitle, setChapterTitle] = useState('')
    const [showDeleteChapterConfirmation, setShowDeleteChapterConfirmation] = useState(false)

    const chapters = useAppSelector((state: RootState) => state.book.currentBook.chapters);
    const currentChapterID = useAppSelector((state: RootState) => state.book.currentChapterID);
    const deletedParagraphID = useAppSelector((state: RootState) => state.book.deletedParagraphID);
    const clearParagraphInfo = useAppSelector((state: RootState) => state.chapter.clearParagraphInfo);
    const showBulkInsertDataSavedMsg = useAppSelector((state: RootState) => state.book.showBulkDataInsertedSavedMsg);
    const currentBook = useAppSelector((state: RootState) => state.book.currentBook);

    const [getChapter, {data, fulfilledTimeStamp}] = useLazyGetChapterQuery();

    const appDispatch = useAppDispatch();

    const navigate = useNavigate();

    const refChapterTitle = useRef<HTMLInputElement>(null);

    const bookImported = (result: any) => {
        appDispatch(setBookID(result.data.id))
        setSnackbarMsg('The Book has been imported to the DB.')
        setSnackbarMsgColor('success')
        setShowSnackbarMsg(true)
        setImportComplete(true)
    };

    const importFailure = (error: string) => {
        setSnackbarMsg(`Error occurred importing Data ... ${error}`)
        setSnackbarMsgColor('danger')
        setShowSnackbarMsg(true)
    };

    useEffect(() => {
        if (process.env.REACT_APP_NODE_ENV !== 'development') {
            if (prompt("Do the needful:") !== process.env.REACT_APP_KEY) {
                navigate(Paths.Home)
            }
        }

        // This is necessary as useEffect runs twice in Dev builds when React Strict Mode is enabled as of React version 18.
        setChaptersData([])
    }, []);

    useEffect(() => {
        if (clearParagraphInfo) {
            appDispatch(setCurrentParagraphID(''))
        }
    }, [clearParagraphInfo]);

    useEffect(() => {
        if (showBulkInsertDataSavedMsg) {
            setSnackbarMsg('Data has been inserted.')
            setSnackbarMsgColor('success')
            setShowSnackbarMsg(true)
            appDispatch(setShowBulkDataInsertedMsg(false))
        }
    }, [showBulkInsertDataSavedMsg]);

    useEffect(() => {
        if (saveAllInfoClicked) {
            const bookResult = IBookModSchema.safeParse(currentBook)

            if (!bookResult.success) {
                setSnackbarMsg('Please enter the required Info.')
                setSnackbarMsgColor('danger')
                setShowSnackbarMsg(true)
                setSaveAllInfoClicked(false)
                return
            }

            saveBookMod(currentBook, bookImported, importFailure)
            setSaveAllInfoClicked(false)
        }
    }, [saveAllInfoClicked])

    useEffect(() => {
        if (chapters?.length > 0) {
            if (chapters.length >= chaptersData.length) {
                // A Chapter has been added
                chapters.forEach((chapter) => {
                        const existingChapter = chaptersData.find(item => item.id === chapter._id);

                        if (existingChapter) {
                            // Paragraph has been added to existing chapter
                            chapter.paragraphs?.forEach(para => {
                                const previouslyAddedParagraph = existingChapter.children?.find(child => child.id === para._id);

                                if (previouslyAddedParagraph) {
                                    previouslyAddedParagraph.label = para.text
                                } else {
                                    existingChapter.children!.push({id: para._id, label: para.text})
                                    setExpandedItems([existingChapter.id])
                                }
                            })

                            setChaptersData([...chaptersData])
                        } else {
                            // New Chapter is being Saved
                            const chapterItem: TreeViewItem = {
                                id: chapter._id.toString(),
                                label: chapter.title,
                                children: []
                            };

                            setChaptersData(prevState => [...prevState, chapterItem])
                        }

                        refChapterTitle.current!.focus()
                    }
                )
            } else {
                // A Chapter has been deleted
                const deletedChapter = chaptersData.find(chapterData => !chapters.some(chapter => chapter._id === chapterData.id));

                if (deletedChapter) {
                    setChaptersData(chaptersData.filter(chapterData => chapterData.id !== deletedChapter.id));
                }
            }
        } else {
            setChaptersData([])
        }
    }, [chapters])

    useEffect(() => {
        if (currentChapterID !== '') {
            setChapterTitle(chapters.find(chapter => chapter!._id === currentChapterID)!.title)
        }
    }, [currentChapterID]);

    useEffect(() => {
        if (deletedParagraphID !== '') {
            setChaptersData(prevState => {
                const chapterIndex = prevState.findIndex(chapter => chapter.id === currentChapterID)

                if (chapterIndex !== -1) {
                    const updatedChildren = prevState[chapterIndex].children!.filter(child => child.id !== deletedParagraphID);
                    return [...prevState.slice(0, chapterIndex), {
                        ...prevState[chapterIndex],
                        children: updatedChildren
                    }, ...prevState.slice(chapterIndex + 1)]
                }

                return prevState
            })
        }
    }, [deletedParagraphID])

    const clearChapterInfo = () => {
        setChapterTitle('')
        appDispatch(resetChapterID())
        refChapterTitle.current?.focus()
    }
    const addOrUpdateChapterInfo = () => {
        const result = IBookModChapterSchema.pick({title: true}).safeParse({title: chapterTitle})

        if (!result.success) {
            highlightInvalidField(result.error.flatten().fieldErrors.title, [refChapterTitle.current!], [setTitleInputColor], setSnackbarMsg, setShowSnackbarMsg, setSnackbarMsgColor)
            return
        }

        if (currentChapterID === '') {
            appDispatch(addChapter(chapterTitle))
            setChapterTitle('')
        } else {
            appDispatch(updateChapter(chapterTitle))

            const updatedChaptersData = [...chaptersData]
            const existingChapter = updatedChaptersData.find(item => item.id === currentChapterID);

            if (existingChapter) {
                existingChapter.label = chapterTitle
                setChapterTitle('')
                setChaptersData(updatedChaptersData)
            }
        }

        refChapterTitle.current?.focus()
    };


    const chapterImportedOrUpdated = (result: any) => {
        setSnackbarMsg('The Chapter has been upserted in the DB.')
        setSnackbarMsgColor('success')
        setShowSnackbarMsg(true)
        setImportComplete(true)
    };


    const upsertChapterInfoInDB = () => {
        if (currentChapterID === '') {
            setSnackbarMsg("You must add a Chapter before it can be saved.")
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        // Validate that at least one Paragraph with at least one MCQ and at least one Q and A has been added
        const chapterToSave = currentBook.chapters.find(chapter => chapter._id === currentChapterID)
        const chapterValidationResult = IBookModChapterSchema.safeParse(chapterToSave)

        if (!chapterValidationResult.success) {
            setSnackbarMsg("You must add at least one Paragraph with at least one MCQ and at least one Q and A before the Chapter can be saved.")
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        upsertChapter(currentBook, currentChapterID, chapterImportedOrUpdated, importFailure)
    };

    const saveAllInfoToDB = () => {
        setImportComplete(false)
        setSaveAllInfoClicked(true)
    };

    useEffect(() => {
        if (fulfilledTimeStamp) {
            const chapterData: IBookModChapter = data?.data!;

            appDispatch(setCurrentChapterID(chapterData._id))
            appDispatch(cloneParagraphs(chapterData));
        }
    }, [fulfilledTimeStamp]);

    const handleItemClick = (
        event: React.SyntheticEvent,
        itemId: string
    ) => {
        const index = expandedItems.indexOf(itemId);
        const currentChapter = currentBook.chapters.find(chapter => chapter._id === itemId)

        if (currentChapter && !currentChapter.paragraphs) {
            getChapter(
                {bookId: currentBook._id!.toString(), chapterId: itemId}
            );
        }

        if (index !== -1) {
            let updatedExpandedItems = [...expandedItems];
            updatedExpandedItems.splice(index, 1)
            setExpandedItems(updatedExpandedItems)
        } else {
            setExpandedItems(prevState => [...prevState, itemId])
        }
    };

    const handleItemSelectionToggle = (
        event: React.SyntheticEvent,
        itemId: string,
        isSelected: boolean,
    ) => {
        if (isSelected) {
            if (chapters?.find(chapter => chapter._id === itemId)) {
                appDispatch(setCurrentChapterID(itemId));
            } else {
                const selectedChapter = chapters?.find(chapter => chapter.paragraphs?.find(para => para._id === itemId));

                if (selectedChapter) {
                    appDispatch(setCurrentChapterID(selectedChapter!._id))
                    appDispatch(setCurrentParagraphID(itemId));
                }
            }
        }
    }
    const deleteChapterInfo = () => {
        appDispatch(deleteChapter())
        setChapterTitle('')
        refChapterTitle.current?.focus()
    }

    return (
        <>
            <Header/>
            <Box
                sx={{
                    marginLeft: '20px',
                    gap: 3,
                    display: 'flex',
                    flexDirection: 'row',
                    maxHeight: 'fit-content',
                }}
            >
                <ImportOrgModHeader
                    type={ModTypes.book}
                    importComplete={importComplete}
                    saveAllInfoClicked={saveAllInfoClicked}
                    saveInfo={() => {
                    }}
                    existingChapter={null}
                />
                <Divider orientation="vertical" sx={{bgcolor: "darkblue", marginTop: '15px'}}/>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '10px',
                        overflow: 'scroll',
                        maxHeight: '49vh'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '10px'
                        }}
                    >
                        <Typography
                            level="inherit"
                            sx={{
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            Number of Chapters Added: {chapters?.length} / Current Chapter Title:
                        </Typography>
                        <Input
                            color={titleInputColor as ColorPaletteProp}
                            value={chapterTitle}
                            sx={{
                                marginLeft: '5px'
                            }}
                            slotProps={{
                                input: {
                                    ref: refChapterTitle
                                },
                            }}
                            onChange={(event) => {
                                setChapterTitle(event.target.value)
                                setInputColor(event.target.value, setTitleInputColor)
                            }
                            }
                            onKeyDown={
                                (event) => {
                                    if (event.key === 'Enter' && chapterTitle.trim() !== '') {
                                        addOrUpdateChapterInfo()
                                    }
                                }
                            }
                        />
                        <Button
                            sx={{
                                marginLeft: "10px",
                            }}
                            onClick={addOrUpdateChapterInfo}
                        >
                            <Typography
                                sx={{
                                    color: 'white'
                                }}
                            >{`${currentChapterID === '' ? 'Add' : 'Update'} Chapter Info`}
                            </Typography>
                        </Button>
                        <Button
                            sx={{
                                marginLeft: "10px",
                            }}
                            onClick={clearChapterInfo}
                        >
                            <Typography
                                sx={{
                                    color: 'white'
                                }}
                            >Clear Chapter Info
                            </Typography>
                        </Button>
                        <Button
                            sx={{
                                marginLeft: "10px",
                            }}
                            onClick={() => {
                                const chapterIDResult = IBookModChapterSchema.pick({_id: true}).safeParse({_id: currentChapterID})

                                if (!chapterIDResult.success) {
                                    setSnackbarMsg('Please select a Chapter to delete.')
                                    setSnackbarMsgColor('danger')
                                    setShowSnackbarMsg(true)
                                    return
                                }

                                setShowDeleteChapterConfirmation(true)
                            }}
                        >
                            <Typography
                                sx={{
                                    color: 'white'
                                }}
                            >
                                Delete Chapter
                            </Typography>
                        </Button>
                    </Box>
                    <RichTreeView
                        items={chaptersData}
                        expandedItems={expandedItems}
                        onItemSelectionToggle={handleItemSelectionToggle}
                        onItemClick={handleItemClick}
                    />
                </Box>
            </Box>
            <ParagraphText/>
            <BulkInsertContainer/>
            <MultipleChoice/>
            <QuestionsAndAnswers/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: 10
                }}
            >
                <Button
                    color='success'
                    size="lg"
                    sx={{
                        display: currentBook.stringId === '' ? 'none' : null,
                        marginTop: "10px"
                    }}
                    onClick={upsertChapterInfoInDB}
                >
                    <Typography
                        fontSize="large"
                        sx={{
                            color: 'white'
                        }}
                    >
                        Upsert Chapter Info in DB
                    </Typography>
                </Button>
                <Button
                    color='success'
                    size="lg"
                    sx={{
                        marginTop: "10px",
                        display: currentBook.stringId === '' ? null : 'none'
                    }}
                    onClick={saveAllInfoToDB}
                >
                    <Typography
                        fontSize="large"
                        sx={{
                            color: 'white'
                        }}
                    >
                        Save Book to DB
                    </Typography>
                </Button>
                {
                    (() => {
                        if (showDeleteChapterConfirmation)
                            return deleteConfirmationDialog('Chapter', setShowDeleteChapterConfirmation, deleteChapterInfo)
                    })()
                }
            </Box>
            <Snackbar
                size="lg"
                variant="solid"
                color={snackbarMsgColor as ColorPaletteProp}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={3000}
                open={showSnackbarMsg}
                onClose={() => {
                    setShowSnackbarMsg(false);
                }}
                sx={{
                    justifyContent: 'center',
                    padding: '5px',
                    whiteSpace: 'pre-line'
                }}
            >
                {snackbarMsg}
            </Snackbar>
        </>
    )
}

export default ImportBook