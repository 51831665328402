import {z} from "zod";
import {createZodSchema} from "src/utils/create-zod-schema"
import {
    IBookMod,
    IBookModChapter,
    IBookModChapterParagraph,
    IBookModChapterParagraphMultiple_choice,
    IBookModChapterParagraphQuestions_and_answer
} from "@backend/mongoose.gen"
import ObjectID from "bson-objectid";

// Create the base Zod schemas
const BaseIBookModSchema = createZodSchema<IBookMod>();
const BaseIBookModChapterSchema = createZodSchema<IBookModChapter>();
const BaseIBookModChapterParagraphSchema = createZodSchema<IBookModChapterParagraph>();
const BaseIBookModChapterParagraphMultipleChoiceSchema = createZodSchema<IBookModChapterParagraphMultiple_choice>()
const BaseIBookModChapterParagraphQuestionsAndAnswerSchema = createZodSchema<IBookModChapterParagraphQuestions_and_answer>()


export const IBookModChapterParagraphQuestionsAndAnswerSchema = BaseIBookModChapterParagraphQuestionsAndAnswerSchema.extend({
    _id: z.string().min(1),
    question: z.string().min(1, "Question must not be empty"),
    answer: z.string().min(1, "Answer must not be empty")
})

export const IBookModChapterParagraphMultipleChoiceSchema = BaseIBookModChapterParagraphMultipleChoiceSchema.extend({
    _id: z.string().min(1),
    question: z.string().trim().min(1, "Question must not be empty"),
    answer: z.string().trim().min(1, "Answer must not be empty"),
    options: z.array(z.string().trim().min(1)).min(3, "There must be 3 Options").max(3, "There must be 3 Options")
})

export const IBookModChapterParagraphSchema = BaseIBookModChapterParagraphSchema.extend({
    _id: z.string().min(1),
    text: z.string().min(1, "Paragraph text must not be empty"),
    multiple_choices: z.array(IBookModChapterParagraphMultipleChoiceSchema).min(1, "Paragraph must have at least one multiple choice"),
    questions_and_answers: z.array(IBookModChapterParagraphQuestionsAndAnswerSchema).min(1, "Paragraph must have at least one question and answer")
})

// Extend the base schema with custom validations
export const IBookModChapterSchema = BaseIBookModChapterSchema.extend({
    _id: z.string().min(1),
    title: z.string().trim().min(1, "Title must be present"),
    paragraphs: z.array(IBookModChapterParagraphSchema).min(1, "Chapter must have at least one paragraph"),
})

export const IBookModSchema = BaseIBookModSchema.extend({
    _id: z.instanceof(ObjectID).optional(),
    // 'stringId' is needed below because, if book.current._id is populated directly in bookSlice, the following error occurs:
    // A non-serializable value was detected in the state, in the path: `book.currentBook._id`. Value: ObjectID {_bsontype: 'ObjectID', id: 'g\x10Ù®½¨:wÒ\x1C\x0EÙ'}
    stringId: z.string().optional(),
    academic_year: z.string().trim().min(1, "Academic year must be present"),
    org: z.string().trim().min(1, "Organization must be present"),
    grade: z.number().min(4, "Grade must be 4 or above").max(12, "Grade must be 12 or less"),
    subject: z.string().trim().min(1, "Subject must be present"),
    serial_no: z.number().min(1, "Serial No must be present"),
    title: z.string().trim().min(1, "Title must be present"),
    desc: z.string().optional(),
    type: z.string().trim().min(1, "Type must be present"),
    chapters: z.array(IBookModChapterSchema).min(1, "Book must have at least one chapter"),
    available: z.boolean(),
    added_on: z.date().optional()
})