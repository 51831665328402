import React, {FC, useEffect, useRef, useState} from 'react';
import {TextInputColor} from "src/utils/constants";
import {Box, Button, ColorPaletteProp, FormLabel, Snackbar, Textarea} from "@mui/joy";
import {highlightInvalidField, newObjectId, setInputColor} from "src/utils/functions";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {addParagraph, setCurrentParagraphID, deleteParagraph, updateParagraphText} from "src/slices/bookSlice";
import {IBookModChapterParagraphSchema, IBookModChapterSchema} from "src/zodSchemas";
import Typography from "@mui/joy/Typography";

export const ParagraphText: FC = () => {
    const [paragraphText, setParagraphText] = useState('');
    const [textInputColor, setTextInputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [snackbarMsg, setSnackbarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [snackbarMsgColor, setSnackbarMsgColor] = useState("danger")

    const currentChapterID = useAppSelector((state: RootState) => state.book.currentChapterID);
    const currentParagraphID = useAppSelector((state: RootState) => state.book.currentParagraphID);
    const chapters = useAppSelector((state: RootState) => state.book.currentBook.chapters);
    const clearParagraphInfo = useAppSelector((state: RootState) => state.chapter.clearParagraphInfo);

    const refTextArea = useRef<HTMLTextAreaElement | null>(null);

    const appDispatch = useAppDispatch();

    useEffect(() => {
        if (currentChapterID !== '' && currentParagraphID !== '') {
            setParagraphText(chapters.find(c => c._id === currentChapterID)?.paragraphs.find(p => p._id === currentParagraphID)!?.text)
        }
    }, [currentParagraphID]);

    useEffect(() => {
        if (clearParagraphInfo) {
            setParagraphText('')
            refTextArea.current!.focus()
        }
    }, [clearParagraphInfo]);

    const saveOrUpdateParagraphInfo = () => {
        const chapterIDResult = IBookModChapterSchema.pick({_id: true}).safeParse({_id: currentChapterID})

        if (!chapterIDResult.success) {
            setSnackbarMsg('Please add and select a Chapter before adding a Paragraph.')
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        const paraTextResult = IBookModChapterParagraphSchema.pick({text: true}).safeParse({text: paragraphText})

        if (!paraTextResult.success) {
            highlightInvalidField(paraTextResult.error.flatten().fieldErrors.text, [refTextArea.current!], [setTextInputColor], setSnackbarMsg, setShowSnackbarMsg, setSnackbarMsgColor)
            return
        }

        const paraIDResult = IBookModChapterParagraphSchema.pick({_id: true}).safeParse({_id: currentParagraphID})

        if (paraIDResult.success) {
            appDispatch(updateParagraphText({_id: currentParagraphID, text: paragraphText}))
        } else {
            appDispatch(addParagraph({
                _id: newObjectId(),
                text: paragraphText,
                questions_and_answers: [],
                multiple_choices: []
            }))
        }

        setParagraphText('')
        refTextArea.current!.focus()
    }

    return (
        <>
            <Box
                sx={{
                    flex: 1,
                    p: 2,
                    boxShadow: 'lg',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1
                }}
            >
                <FormLabel>Paragraph Text *</FormLabel>
                <Textarea color={textInputColor as ColorPaletteProp}
                          style={{width: "100%"}}
                          value={paragraphText}
                          maxRows={5}
                          slotProps={{textarea: {ref: refTextArea}}}
                          onChange={(event) => {
                              setInputColor(event.target.value, setTextInputColor)
                              setParagraphText(event.target.value)
                          }}
                          onKeyDown={
                              (event) => {
                                  if (event.key === 'Enter' && paragraphText.trim() !== '') {
                                      saveOrUpdateParagraphInfo()
                                  }
                              }
                          }
                />
                <Button
                    onClick={saveOrUpdateParagraphInfo}
                >
                    <Typography
                        sx={{
                            color: 'white'
                        }}
                        noWrap
                    >{`${currentParagraphID === '' ? 'Save' : 'Update'} Paragraph Info`}
                    </Typography>
                </Button>
                <Button
                    onClick={
                        () => {
                            setParagraphText('')
                            appDispatch(setCurrentParagraphID(''))
                        }
                    }
                >
                    <Typography
                        sx={{
                            color: 'white'
                        }}
                        noWrap
                    >Clear Paragraph Info</Typography>
                </Button>
                <Button
                    onClick={
                        () => {
                            const paraIDResult = IBookModChapterParagraphSchema.pick({_id: true}).safeParse({_id: currentParagraphID})

                            if (!paraIDResult.success) {
                                setSnackbarMsg('Please select a Paragraph to delete.')
                                setSnackbarMsgColor('danger')
                                setShowSnackbarMsg(true)
                                return
                            }

                            appDispatch(deleteParagraph())
                            setParagraphText('')
                        }
                    }
                >
                    <Typography
                        sx={{
                            color: 'white'
                        }}
                        noWrap
                    >Delete Paragraph</Typography>
                </Button>
            </Box>
            <Snackbar
                size="lg"
                variant="solid"
                color={snackbarMsgColor as ColorPaletteProp}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={3000}
                open={showSnackbarMsg}
                onClose={() => {
                    setShowSnackbarMsg(false);
                }}
                sx={{
                    justifyContent: 'center',
                    padding: '5px',
                    whiteSpace: 'pre-line'
                }}
            >
                {snackbarMsg}
            </Snackbar>
        </>
    )
}


export default ParagraphText
