import React from 'react';
import './App.css';
import {ModCardContainer} from "./components/ModCardContainer";
import {useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {AssessmentHomePage} from "src/components/Spelling/AssessmentHomePage";
import {Page} from "src/slices/pageSlice";
import {ShowWord} from "src/components/Spelling/ShowWord";
import {SpellingAssessmentResult} from "src/components/Spelling/SpellingAssessmentResult";
import {StudyWords} from "src/components/Spelling/StudyWords";
import {ChooseCorrectSpelling} from "src/components/Spelling/ChooseCorrectSpelling";
import {AnswerQuestion} from "src/components/Chapter/AnswerQuestion";
import {ChapterAssessmentResult} from "src/components/Chapter/ChapterAssessmentResult";
import {ChooseCorrectAnswerInChapter} from "src/components/Chapter/ChooseCorrectAnswerInChapter";
import {TypeCorrectAnswerInChapter} from "src/components/Chapter/TypeCorrectAnswerInChapter";
import {SelectChapterToStudy} from "src/components/Chapter/SelectChapterToStudy";

const Home = () => {
    const currentPage = useAppSelector((state: RootState) => state.pages.value);

    return (
        <div>
            {
                (() => {
                    if (currentPage === Page.HOME) return (
                        <ModCardContainer/>
                    )
                    if (currentPage === Page.ASSESSMENT) return (
                        <AssessmentHomePage/>
                    )
                    if (currentPage === Page.SHOW_WORD) return (
                        <ShowWord/>
                    )
                    if (currentPage === Page.SPELLING_RESULT) return (
                        <SpellingAssessmentResult/>
                    )
                    if (currentPage === Page.STUDY_WORDS) return (
                        <StudyWords/>
                    )
                    if (currentPage === Page.CHOOSE_CORRECT_SPELLING) return (
                        <ChooseCorrectSpelling/>
                    )
                    if (currentPage === Page.ANSWER_QUESTION) return (
                        <AnswerQuestion/>
                    )
                    if (currentPage === Page.CHAPTER_RESULT) return (
                        <ChapterAssessmentResult/>
                    )
                    if (currentPage === Page.CHOOSE_CORRECT_ANSWER_IN_CHAPTER) return (
                        <ChooseCorrectAnswerInChapter/>
                    )
                    if (currentPage === Page.TYPE_CORRECT_ANSWER_IN_CHAPTER) return (
                        <TypeCorrectAnswerInChapter/>
                    )
                    if (currentPage === Page.SELECT_CHAPTER_TO_STUDY) return (
                        <SelectChapterToStudy/>
                    )
                })()
            }
        </div>
    )
};

export default Home;