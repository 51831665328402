import React, {FC, useEffect, useState} from "react"
import {Box, Button, ColorPaletteProp, Snackbar, Textarea} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {RichTreeView, TreeViewBaseItem, useTreeViewApiRef} from "@mui/x-tree-view";
import {saveMultipleChoiceArray, saveQandAArray, setShowBulkDataInsertedMsg} from "src/slices/bookSlice"
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {TreeViewItem} from "src/utils/interfaces";
import {IBookModChapterParagraphSchema, IBookModChapterSchema} from "src/zodSchemas";
import {RootState} from "src/app/store";
import {newObjectId} from "src/utils/functions";

export enum BulkInsertType {
    MCQ = 'mcq',
    QA = 'qa'
}

interface Props {
    typeOfInsert: BulkInsertType
}

const BulkInsert: FC<Props> = ({typeOfInsert}) => {
    const [bulkInsertData, setBulkInsertData] = useState<TreeViewItem[]>([])
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [expandedItems, setExpandedItems] = useState<string[]>([]);
    const [questionIDs, setQuestionIDs] = useState<string[]>([]);
    const [bulkInsertText, setBulkInsertText] = useState('')
    const [snackbarMsgColor, setSnackbarMsgColor] = useState("danger")
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [snackbarMsg, setSnackbarMsg] = useState('')

    const currentChapterID = useAppSelector((state: RootState) => state.book.currentChapterID);
    const currentParagraphID = useAppSelector((state: RootState) => state.book.currentParagraphID)

    const toggledItemRef = React.useRef<{ [itemId: string]: boolean }>({});
    const apiRef = useTreeViewApiRef();
    const appDispatch = useAppDispatch();

    const insertItems = () => {
        let errorMsg = ''

        const chapterIDResult = IBookModChapterSchema.pick({_id: true}).safeParse({_id: currentChapterID})

        if (errorMsg === '' && !chapterIDResult.success) {
            errorMsg = 'Please add and select a Chapter before adding a Paragraph.'
        }

        const paragraphIDResult = IBookModChapterParagraphSchema.pick({_id: true}).safeParse({_id: currentParagraphID})

        if (errorMsg === '' && !paragraphIDResult.success) {
            errorMsg = 'Please add and select a Paragraph before inserting MCQs and QAs.'
        }

        if (errorMsg === '' && bulkInsertData.length === 0) {
            errorMsg = 'Please add data to insert.'
        }

        if (errorMsg === '' && selectedItems.length === 0) {
            errorMsg = 'Please select data to insert.'
        }

        if (errorMsg !== '') {
            setSnackbarMsg(errorMsg)
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        if (typeOfInsert === BulkInsertType.MCQ) {
            appDispatch(saveMultipleChoiceArray(bulkInsertData.filter(item => selectedItems.includes(item.id)).map(question => ({
                _id: newObjectId(),
                question: question.label,
                answer: question.children![0].label,
                options: question.children!.slice(1, 4).map(option => option.label)
            }))))
        } else if (typeOfInsert === BulkInsertType.QA) {
            appDispatch(saveQandAArray(bulkInsertData.filter(item => selectedItems.includes(item.id)).map(question => ({
                _id: newObjectId(),
                question: question.label,
                answer: question.children![0].label
            }))))
        }

        setBulkInsertData([])
        setBulkInsertText('')
        appDispatch(setShowBulkDataInsertedMsg(true))
    };

    useEffect(() => {
        if (questionIDs.length > 0) {
            setExpandedItems(questionIDs.slice())
        }
    }, [questionIDs]);

    const handleItemSelectionToggle = (
        event: React.SyntheticEvent,
        itemId: string,
        isSelected: boolean,
    ) => {
        toggledItemRef.current[itemId] = isSelected;
    };

    const getItemDescendantsIds = (item: TreeViewBaseItem) => {
        const ids: string[] = [];
        item.children?.forEach((child) => {
            ids.push(child.id);
            ids.push(...getItemDescendantsIds(child));
        });

        return ids;
    };

    const handleSelectedItemsChange = (
        event: React.SyntheticEvent,
        newSelectedItems: string[],
    ) => {
        setSelectedItems(newSelectedItems);

        // Select / unselect the children of the toggled item
        const itemsToSelect: string[] = [];
        const itemsToUnSelect: { [itemId: string]: boolean } = {};
        Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
            // @ts-ignore
            const item = apiRef.current!.getItem(itemId);
            if (isSelected) {
                itemsToSelect.push(...getItemDescendantsIds(item));
            } else {
                getItemDescendantsIds(item).forEach((descendantId) => {
                    itemsToUnSelect[descendantId] = true;
                });
            }
        });

        const newSelectedItemsWithChildren = Array.from(
            new Set(
                [...newSelectedItems, ...itemsToSelect].filter(
                    (itemId) => !itemsToUnSelect[itemId],
                ),
            ),
        );

        setSelectedItems(newSelectedItemsWithChildren);

        toggledItemRef.current = {};
    };

    const parseQandAs = (input: string): TreeViewItem[] => {
        const lines = input.split('\n').map(line => line.trim()).filter(line => line !== ''); // Remove empty lines
        const result: TreeViewItem[] = [];
        let idCounter = 1;
        let numberOfLinesAfterQuestion = BulkInsertType.MCQ === typeOfInsert ? 4 : 1

        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];

            // Remove any initial number followed by a space
            if (/^\d+\.\s/.test(line)) {
                line = line.replace(/^\d+\.\s*/, '');
            }

            // Every question is followed by 4 answers for MCQ. For QA, there is only the question and the answer (1 line).
            if (i + numberOfLinesAfterQuestion < lines.length) {
                const question: TreeViewItem = {
                    id: idCounter.toString(),
                    label: line,
                    checked: true,
                    isExpanded: true,
                    children: []
                };

                for (let j = 1; j <= numberOfLinesAfterQuestion; j++) {
                    let answerLine = lines[i + j].trim();

                    if (answerLine.endsWith('.')) {
                        answerLine = answerLine.slice(0, -1); // trim the period
                    }

                    // Remove initial dashes and spaces for answers
                    if (/^-/.test(answerLine)) {
                        answerLine = answerLine.replace(/^-+\s*/, '');
                    }

                    const answer: TreeViewItem = {
                        id: `${idCounter}.${j}`,
                        label: answerLine,
                        disabled: true
                    };

                    question.children!.push(answer);
                }

                result.push(question);
                setQuestionIDs(prevState => [...prevState, question.id])
                idCounter++;
                i += numberOfLinesAfterQuestion; // Skip the 4 answer lines for MCQ. For QA, skip the 1 answer line.
            }
        }

        setSelectedItems(questionIDs)

        return result;
    };

    const handleExpandedItemsChange = (
        event: React.SyntheticEvent,
        itemIds: string[],
    ) => {
        setExpandedItems(itemIds);
    };

    const isItemDisabled = (item: TreeViewItem) => !!item.disabled;

    return (
        <>
            <Box
                sx={{
                    flex: 1,
                    p: 3,
                    boxShadow: 'lg',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Textarea
                    maxRows={1}
                    value={bulkInsertText}
                    placeholder={typeOfInsert === BulkInsertType.MCQ ? 'Paste MCQs Here' : 'Paste QAs Here'}
                    style={{width: "100%"}}
                    onChange={(event) => {
                        setBulkInsertData([])
                        setQuestionIDs([])
                        setExpandedItems([])
                        setBulkInsertData(parseQandAs(event.target.value))
                        setBulkInsertText(event.target.value)
                    }
                    }
                />
                <Box>
                    <RichTreeView
                        multiSelect
                        checkboxSelection
                        apiRef={apiRef}
                        items={bulkInsertData}
                        expandedItems={expandedItems}
                        isItemDisabled={isItemDisabled}
                        selectedItems={selectedItems}
                        onSelectedItemsChange={handleSelectedItemsChange}
                        onItemSelectionToggle={handleItemSelectionToggle}
                        onExpandedItemsChange={handleExpandedItemsChange}
                    />
                </Box>
                <Button
                    sx={{
                        marginTop: "10px"
                    }}
                    onClick={
                        () => insertItems()
                    }
                >
                    <Typography
                        sx={{
                            color: 'white'
                        }}
                    >
                        Insert
                    </Typography>
                </Button>
            </Box>
            <Snackbar
                size="lg"
                variant="solid"
                color={snackbarMsgColor as ColorPaletteProp}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={3000}
                open={showSnackbarMsg}
                onClose={() => {
                    setShowSnackbarMsg(false);
                }}
                sx={{
                    justifyContent: 'center',
                    padding: '5px',
                    whiteSpace: 'pre-line'
                }}
            >
                {snackbarMsg}
            </Snackbar>
        </>
    );
};

export default BulkInsert;