import axios from "axios";
import {IBookMod, IBookModChapter, IMod, IModRecord} from "@backend/mongoose.gen";
import {z} from "zod";
import {IBookModSchema} from "src/zodSchemas";
import ObjectID from "bson-objectid";
import {newObjectId} from "src/utils/functions";
import {IOrgModInfoSchema} from "src/components/ImportOrgModHeader";
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';


export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8020' : ''

interface ChapterRequest {
    bookId: string;
    chapterId: string;
}

// Define the API endpoint
export const modsAPI = createApi({
    reducerPath: 'modsAPI',
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL}),
    endpoints: (builder) => ({
        getChapter: builder.query<{ status: string, data: IBookModChapter }, ChapterRequest>({
            query: (body) => ({
                url: '/mods/books/chapter/get',
                method: 'POST',
                body,
            })
        }),
        getBook: builder.query<{ status: string, data: IBookMod }, { bookId: string }>({
            query: (body) => ({
                url: '/mods/book/get',
                method: 'POST',
                body
            })
        })
    })
});

export const {useLazyGetBookQuery, useLazyGetChapterQuery} = modsAPI;

export const getAvailableMods = (requestBody: object, successFunction: any, failureFunction: any): void => {
    const url = `${BASE_URL}/mods/get-available`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response.data.data)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const getAllBooks = (successFunction: any, failureFunction: any): void => {
    const url = `${BASE_URL}/mods/books/get-all`

    axios.post(url, null)
        .then(response => {
            successFunction(response.data.data)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const getUnavailableBooks = (successFunction: any, failureFunction: any): void => {
    const url = `${BASE_URL}/mods/books/get-unavailable`

    axios.post(url, null)
        .then(response => {
            successFunction(response.data.data)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const saveSpellingMod = (requestBody: IMod, successFunction: any, failureFunction: any): void => {
    const url = `${BASE_URL}/mods/add-spelling`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response.data.data)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const saveBookMod = (requestBody: z.infer<typeof IBookModSchema>, successFunction: (result: any) => void, failureFunction: any): void => {
    const url = `${BASE_URL}/mods/add-book`
    const newRequestBody = {...requestBody};

    newRequestBody._id = ObjectID(newObjectId())
    axios.post(url, IBookModSchema.parse(newRequestBody))
        .then(response => {
            successFunction(response)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const updateBookModInfo = (requestBody: z.infer<typeof IOrgModInfoSchema>, successFunction: (result: any) => void, failureFunction: any): void => {
    const url = `${BASE_URL}/mods/update-book-mod-info`
    const newRequestBody = {...requestBody}

    newRequestBody._id = ObjectID(newRequestBody.stringId!)
    axios.post(url, IOrgModInfoSchema.parse(newRequestBody))
        .then(response => {
            successFunction(response)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const upsertChapter = (requestBody: z.infer<typeof IBookModSchema>, currentChapterID: string, successFunction: (result: any) => void, failureFunction: any): void => {
    const url = `${BASE_URL}/mods/book/upsert-chapter`
    const newRequestBody = {...requestBody}

    if (!newRequestBody.stringId) {
        newRequestBody.stringId = newRequestBody._id!.toString()
    }

    // Filter out all chapters that don't match the one being saved.
    newRequestBody.chapters = newRequestBody.chapters.filter(chapter => chapter._id === currentChapterID)

    axios.post(url, newRequestBody)
        .then(response => {
            successFunction(response)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const startChapterTest = (requestBody: IModRecord, successFunction: (result: any) => void, failureFunction: any): void => {
    const url = `${BASE_URL}/test/chapter/start`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response.data.id)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const completeChapterTest = (chapterTest_ID: string, successFunction: (result: any) => void, failureFunction: any): void => {
    if (!chapterTest_ID) {
        return
    }

    const url = `${BASE_URL}/test/chapter/complete`

    axios.post(url, {
        id: chapterTest_ID
    })
        .then(response => {
            successFunction(response)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const startChapterStudy = (requestBody: IModRecord, successFunction: (result: any) => void, failureFunction: any): void => {
    const url = `${BASE_URL}/study/chapter/start`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response.data.id)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const completeChapterStudy = (chapterStudy_ID: string, successFunction: (result: any) => void, failureFunction: any): void => {
    if (!chapterStudy_ID) {
        return
    }

    const url = `${BASE_URL}/study/chapter/complete`

    axios.post(url, {
        id: chapterStudy_ID
    })
        .then(response => {
            successFunction(response)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const startSpellingTest = (requestBody: IModRecord, successFunction: (result: any) => void, failureFunction: any): void => {
    const url = `${BASE_URL}/test/spelling/start`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response.data.id)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const completeSpellingTest = (spellingTest_ID: string, successFunction: (result: any) => void, failureFunction: any): void => {
    if (!spellingTest_ID) {
        return
    }

    const url = `${BASE_URL}/test/spelling/complete`

    axios.post(url, {
        id: spellingTest_ID
    })
        .then(response => {
            successFunction(response)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const startSpellingStudy = (requestBody: IModRecord, successFunction: (result: any) => void, failureFunction: any): void => {
    const url = `${BASE_URL}/study/spelling/start`

    axios.post(url, requestBody)
        .then(response => {
            successFunction(response.data.id)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const completeSpellingStudy = (spellingStudy_ID: string, successFunction: (result: any) => void, failureFunction: any): void => {
    if (!spellingStudy_ID) {
        return
    }

    const url = `${BASE_URL}/study/spelling/complete`

    axios.post(url, {
        id: spellingStudy_ID
    })
        .then(response => {
            successFunction(response)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}

export const getMod = (id: string, successFunction: (result: IBookMod | IMod) => void, failureFunction: any): void => {
    const url = `${BASE_URL}/id/${id}`

    axios.post(url, {})
        .then(response => {
            successFunction(response.data.modData)
        })
        .catch((failure: string) => {
            failureFunction(failure)
        })
}
