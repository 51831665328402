import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ModTypes} from "src/utils/constants";

export enum Page {
    HOME = 'home',
    ASSESSMENT = 'assessment',
    CHAPTER_RESULT = 'chapter_result',
    SHOW_WORD = 'show_word',
    SPELLING_RESULT = 'spelling_result',
    STUDY_WORDS = 'study_words',
    CHOOSE_CORRECT_SPELLING = 'choose_correct_spelling',
    ANSWER_QUESTION = 'answer_question',
    CHOOSE_CORRECT_ANSWER_IN_CHAPTER = 'choose_correct_answer_in_chapter',
    TYPE_CORRECT_ANSWER_IN_CHAPTER = 'type_correct_answer_in_chapter',
    SELECT_CHAPTER_TO_STUDY = 'select_chapter_to_study'
}

export interface PageState {
    assessmentPageTitle: string,
    assessmentPageType: string,
    value: Page,
}

const initialState: PageState = {
    assessmentPageTitle: "",
    assessmentPageType: ModTypes.none,
    value: Page.HOME,
}

export const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<Page>) => {
            state.value = action.payload;
        },
        setAssessmentPageTitle: (state, action: PayloadAction<string>) => {
            state.assessmentPageTitle = action.payload;
        },
        setAssessmentPageType: (state, action: PayloadAction<string>) => {
            state.assessmentPageType = action.payload;
        }
    },
});

export const {
    update,
    setAssessmentPageTitle,
    setAssessmentPageType
} = pageSlice.actions;
export default pageSlice.reducer;