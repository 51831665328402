import React, {FC, useRef, useState} from 'react'
import {
    Box,
    Button,
    ColorPaletteProp,
    IconButton,
    Input,
    List,
    ListItem,
    Sheet,
    Snackbar,
    Table, Textarea
} from "@mui/joy";
import {KeyboardArrowDown} from "@mui/icons-material";
import Typography from "@mui/joy/Typography";
import {getAnswerTextToEnter, highlightInvalidField, newObjectId, setInputColor} from "src/utils/functions";
import {z} from "zod";
import {TextInputColor} from "src/utils/constants";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {deleteConfirmationDialog} from "src/components/Shared/DeleteModal";
import {
    IBookModChapterParagraphQuestionsAndAnswerSchema, IBookModChapterParagraphSchema, IBookModChapterSchema
} from "src/zodSchemas";
import {
    deleteQandAItem,
    editQandAItem,
    saveQandAArray
} from "src/slices/bookSlice";

export const QuestionsAndAnswers: FC = () => {
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [snackbarMsgColor, setSnackbarMsgColor] = useState("neutral")
    const [qAndAInfo, setQAndAInfo] = useState<z.infer<typeof IBookModChapterParagraphQuestionsAndAnswerSchema>>(
        {
            _id: newObjectId(),
            question: '',
            answer: ''
        }
    )
    const [questionInputColor, setQuestionInputColor] = React.useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [answerInputColor, setAnswerInputColor] = React.useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [editClicked, setEditClicked] = useState(false)
    const [rowToDelete, setRowToDelete] = useState('')

    const currentChapterID = useAppSelector((state: RootState) => state.book.currentChapterID);
    const currentParagraphID = useAppSelector((state: RootState) => state.book.currentParagraphID);
    const chapters = useAppSelector((state: RootState) => state.book.currentBook.chapters);

    const qAndAArray: z.infer<typeof IBookModChapterParagraphQuestionsAndAnswerSchema>[] | undefined = useAppSelector((state: RootState) => state.book.currentBook.chapters?.find(chapter => chapter._id === currentChapterID)?.paragraphs?.find(para => para._id === currentParagraphID)?.questions_and_answers)

    const refQuestion = useRef<HTMLTextAreaElement | null>(null);
    const refAnswer = useRef<HTMLInputElement | null>(null);

    const appDispatch = useAppDispatch();

    const cleanUpAfterSaving = (operation: string) => {
        setQAndAInfo({
            _id: newObjectId(),
            question: '',
            answer: ''
        })

        setSnackbarMsg(`Questions And Answers Info has been ${operation}.`)
        setSnackbarMsgColor('success')
        setShowSnackbarMsg(true)
    };

    const answerIsInParagraphText = () => {
        const paragraphText = chapters?.find(chapter => chapter._id === currentChapterID)?.paragraphs?.find(para => para._id === currentParagraphID)!.text

        return (paragraphText!.toLowerCase().includes(qAndAInfo.answer.toLowerCase()))
    }

    const doValidation = () => {
        const chapterIDResult = IBookModChapterSchema.pick({_id: true}).safeParse({_id: currentChapterID})

        if (!chapterIDResult.success) {
            setSnackbarMsg('Please add and select a Chapter before adding a Paragraph.')
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        const paraIDResult = IBookModChapterParagraphSchema.pick({_id: true}).safeParse({_id: currentParagraphID})

        if (!paraIDResult.success) {
            setSnackbarMsg('Please add and select a Paragraph before adding a Multiple Choice Question.')
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        const qaResult = IBookModChapterParagraphQuestionsAndAnswerSchema.safeParse(qAndAInfo)

        if (!qaResult.success) {
            highlightInvalidField(qaResult.error.flatten().fieldErrors.answer, [refAnswer.current!], [setAnswerInputColor], setSnackbarMsg, setShowSnackbarMsg, setSnackbarMsgColor)
            highlightInvalidField(qaResult.error.flatten().fieldErrors.question, [refQuestion.current!], [setQuestionInputColor], setSnackbarMsg, setShowSnackbarMsg, setSnackbarMsgColor)
            return
        }

        if (!editClicked) {
            const qAndAArray = chapters.find(chapter => chapter._id === currentChapterID)!.paragraphs.find(para => para._id === currentParagraphID)!.questions_and_answers
            const questionHasBeenAddedPreviously = (qAndAArray.filter(m => m.question.toLowerCase() === qAndAInfo.question.trim().toLowerCase()).length) > 0

            if (questionHasBeenAddedPreviously) {
                setSnackbarMsg(`'${qAndAInfo.question}' has already been added.`)
                setQAndAInfo(prevState => ({
                    ...prevState,
                    question: ''
                }))
                refQuestion.current!.focus()
                setSnackbarMsgColor('danger')
                setShowSnackbarMsg(true)
                return
            }

            const answerHasBeenAddedPreviously = (qAndAArray.filter(m => m.answer.toLowerCase() === qAndAInfo.answer.trim()).length) > 0

            if (answerHasBeenAddedPreviously) {
                setSnackbarMsg(`'${qAndAInfo.answer}' has already been added.`)
                setQAndAInfo(prevState => ({
                    ...prevState,
                    answer: ''
                }))
                refAnswer.current!.focus()
                setSnackbarMsgColor('danger')
                setShowSnackbarMsg(true)
                return
            }
        }

        const questionAndAnswerAreTheSame = (qAndAInfo.question.trim().toLowerCase() === qAndAInfo.answer.trim().toLowerCase())

        if (questionAndAnswerAreTheSame) {
            setSnackbarMsg('Question and Answer must be different.')
            setQAndAInfo(prevState => ({
                ...prevState,
                answer: ''
            }))
            refAnswer.current!.focus()
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        if (!answerIsInParagraphText()) {
            setSnackbarMsg("Answer must be in the Paragraph Text")
            refAnswer.current!.focus()
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        // Info is valid
        refQuestion.current!.focus()

        if (editClicked) {
            appDispatch(editQandAItem(qAndAInfo))
            setEditClicked(false)
            cleanUpAfterSaving('edited');
        } else {
            appDispatch(saveQandAArray([qAndAInfo]))
            cleanUpAfterSaving('added');
        }
    };

    const deleteQuestionsAndAnswers = () => {
        appDispatch(deleteQandAItem(rowToDelete))
        setShowDeleteConfirmation(false)
        setRowToDelete('')
        setEditClicked(false)
        refQuestion.current?.focus()
    };

    const editQandA = (_idToEdit: string) => {
        setEditClicked(true)
        setQAndAInfo(qAndAArray!.find(qa => qa._id === _idToEdit)!)
        refQuestion.current?.focus()
    };

    return (
        <List size="sm"
              sx={() => ({
                  'marginLeft': '20px',
                  'marginTop': '0px',
                  'marginBottom': '0px',
                  '--ListItem-paddingRight': '16px',
                  '--ListItem-paddingLeft': '21px',
                  '--ListItem-startActionTranslateX': '-50%',
              })}
        >
            <ListItem
                nested
                sx={{my: 0}}
                startAction={
                    <IconButton
                        variant="plain"
                        size="sm"
                        color="neutral"
                        onClick={() => setOpen(!open)}
                    >
                        <KeyboardArrowDown
                            sx={{transform: open ? 'initial' : 'rotate(-90deg)'}}
                        />
                    </IconButton>
                }
            >
                <ListItem>
                    <Typography
                        level="inherit"
                        sx={{
                            fontWeight: open ? 'bold' : undefined,
                            color: open ? 'text.primary' : 'inherit',
                        }}
                    >
                        Paragraph: Questions And Answers
                    </Typography>
                    <div style={{marginTop: '1px'}}>
                        {` ${qAndAArray ? qAndAArray.length : 0}`}
                    </div>
                </ListItem>
                {open && (
                    <Box
                        sx={{
                            flex: 1,
                            p: 3,
                            boxShadow: 'lg',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Textarea color={questionInputColor as ColorPaletteProp}
                                  placeholder='Question *'
                                  style={{width: "100%"}}
                                  value={qAndAInfo.question}
                                  slotProps={{
                                      textarea: {ref: refQuestion},
                                  }}
                                  onChange={(event) => {
                                      let textToEnter = event.target.value?.toLowerCase().startsWith("question:") ? event.target.value.slice(9).trim() : event.target.value

                                      textToEnter = event.target.value?.toLowerCase().startsWith("q:") ? event.target.value.slice(2).trim() : event.target.value

                                      textToEnter = textToEnter.replace(/^\n+/, '');  // Remove hard returns at the start of the text

                                      // Replace any occurrences of one or more newline characters (\n) followed by zero or more whitespace characters (\s*) followed by another newline character (\n) with a single newline character (\n). This effectively removes the hard return from the original string.
                                      textToEnter = textToEnter.replace(/\n\s*\n/g, '\n');
                                      setInputColor(textToEnter, setQuestionInputColor)
                                      setQAndAInfo(prevState => ({
                                              ...prevState,
                                              question: textToEnter,
                                          })
                                      )
                                  }}
                        />
                        <Input color={answerInputColor as ColorPaletteProp}
                               fullWidth={true}
                               placeholder='Answer * (Must be in the Paragraph Text)'
                               value={qAndAInfo.answer}
                               slotProps={{
                                   input: {
                                       ref: refAnswer
                                   },
                               }}
                               onChange={(event) => {
                                   setInputColor(event.target.value, setAnswerInputColor)
                                   setQAndAInfo(prevState => ({
                                           ...prevState,
                                           answer: getAnswerTextToEnter(event.target.value)
                                       })
                                   )
                               }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 3,
                            }}>
                            <Button
                                sx={{
                                    marginTop: "10px"
                                }}
                                onClick={
                                    () => doValidation()
                                }
                            >
                                <Typography sx={{
                                    color: 'white'
                                }}
                                >Save Q and A Info</Typography>
                            </Button>
                            <Button
                                sx={{
                                    marginTop: "10px"
                                }}
                                onClick={
                                    () => {
                                        setEditClicked(false)
                                        setQAndAInfo({
                                            _id: newObjectId(),
                                            question: '', answer: ''
                                        })
                                        refQuestion.current?.focus()
                                    }
                                }
                            >
                                <Typography sx={{
                                    color: 'white'
                                }}
                                >Clear Q and A Info</Typography>
                            </Button>
                        </Box>
                        <div>
                            <Sheet sx={{height: 280, overflow: 'auto', marginLeft: '20px', marginRight: '20px'}}>
                                <Table
                                    aria-label="table with sticky header"
                                    stickyHeader
                                    stickyFooter
                                >
                                    <thead>
                                        <tr>
                                            <th>Question</th>
                                            <th>Answer</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {qAndAArray?.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.question}</td>
                                                <td>{row.answer}</td>
                                                <td>
                                                    <Box sx={{display: 'flex', gap: 1}}>
                                                        <Button size="sm" variant="soft" color="danger"
                                                                onClick={
                                                                    () => {
                                                                        editQandA(row._id)
                                                                    }
                                                                }>
                                                            Edit
                                                        </Button>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{display: 'flex', gap: 1}}>
                                                        <Button size="sm" variant="soft" color="danger"
                                                                onClick={
                                                                    () => {
                                                                        setRowToDelete(row._id)
                                                                        setShowDeleteConfirmation(true)
                                                                    }
                                                                }>
                                                            Delete
                                                        </Button>
                                                    </Box>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan={4} style={{textAlign: 'center'}}>
                                            Count: {qAndAArray?.length}
                                        </td>
                                    </tr>
                                    </tfoot>
                                </Table>
                            </Sheet>
                        </div>
                        {
                            (() => {
                                if (showSnackbarMsg)
                                    return (
                                        <Snackbar
                                            size="lg"
                                            variant="solid"
                                            color={snackbarMsgColor as ColorPaletteProp}
                                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                            autoHideDuration={3000}
                                            open={true}
                                            onClose={() => {
                                                setShowSnackbarMsg(false);
                                            }}
                                            sx={{
                                                justifyContent: 'center',
                                                padding: '5px',
                                                whiteSpace: 'pre-line'
                                            }}
                                        >
                                            {snackbarMsg}
                                        </Snackbar>
                                    )
                            })()
                        }
                        {
                            (() => {
                                if (showDeleteConfirmation)
                                    return deleteConfirmationDialog('Q & A', setShowDeleteConfirmation, deleteQuestionsAndAnswers)
                            })()
                        }
                    </Box>
                )}
            </ListItem>
        </List>
    )
}

export default QuestionsAndAnswers
