import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {useLazyGetBookQuery, useLazyGetChapterQuery} from "src/utils/api-service";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import {List, ListItem, ListItemButton, Snackbar} from "@mui/joy";
import Body from "src/components/Shared/Body";
import ButtonRow from "src/components/Shared/ButtonRow";
import ButtonGroup from "@mui/joy/ButtonGroup";
import Button from "@mui/joy/Button";
import {resizeButtonSX} from "src/utils/ui";
import {Page, update} from "src/slices/pageSlice";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import {IBookModChapter} from "@backend/mongoose.gen";
import Title from "src/components/Shared/Title";
import Description from "src/components/Shared/Description";
import {formatDate} from "src/utils/functions";
import {VoiceAndEnterKeySettings} from "src/components/Shared/VoiceAndEnterKeySettings";
import {saveSelectedChapterInfo} from "src/slices/chapterSlice";
import {useParams} from "react-router-dom";
import {z} from "zod";
import {IBookModSchema} from "src/zodSchemas";
import {setAssessmentCompletedBeforeStudying} from "src/slices/wordsSlice";
import {setCurrentBook} from "src/slices/bookSlice";

export const SelectChapterToStudy: FC = () => {
    const [IDWasInURL, setIDWasInURL] = useState(false)
    const [selectedChapterID, setSelectedChapterID] = useState('')
    const [showSelectChapterSnackbarMsg, setShowSelectChapterSnackbarMsg] = useState(false)

    const currentBook = useAppSelector((state: RootState) => state.book.currentBook);

    const [getBook, {data: bookData, fulfilledTimeStamp: bookFulfilledTimeStamp}] = useLazyGetBookQuery();
    const [getChapter, {data: chapterData, fulfilledTimeStamp: chapterFulfilledTimeStamp}] = useLazyGetChapterQuery();

    const {id} = useParams();
    const appDispatch = useAppDispatch();
    const {setNavigationPathToHomeIfNeeded} = useSharedMgr();

    useEffect(() => {
        setIDWasInURL(!!id)
        appDispatch(setAssessmentCompletedBeforeStudying(false))

        if (id) {
            // A Link to Study a Book has been entered
            getBook(
                {
                    bookId: id
                }
            );
        }
    }, []);

    useEffect(() => {
        if (bookFulfilledTimeStamp) {
            const bookDataInstance: z.infer<typeof IBookModSchema> = {
                ...bookData?.data!,
                _id: undefined,
                stringId: id
            };

            appDispatch(setCurrentBook(bookDataInstance as z.infer<typeof IBookModSchema>))
        }
    }, [bookFulfilledTimeStamp]);

    useEffect(() => {
        if (chapterFulfilledTimeStamp) {
            const chapterDataInstance: IBookModChapter = chapterData?.data!;

            appDispatch(saveSelectedChapterInfo({
                chapterID: selectedChapterID,
                title: chapterDataInstance.title,
                paragraphs: chapterDataInstance.paragraphs,
                assessmentCompletedBeforeStudying: false
            }))

            setNavigationPathToHomeIfNeeded(IDWasInURL)
            appDispatch(update(Page.CHOOSE_CORRECT_ANSWER_IN_CHAPTER))
        }
    }, [chapterFulfilledTimeStamp]);

    const handleChapterListItemClick = (index: string) => {
        setSelectedChapterID(index);
    };

    return (
        <>
            {
                <Sheet
                    sx={{
                        background: "linear-gradient(to right, #48BB78, #3B82F6)",
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <Title title={`Select a Chapter to Study from '${currentBook.title}'`}/>
                    <Description description={formatDate(new Date())}/>
                    <Body boxGap={5}>
                        <Sheet
                            variant="outlined"
                            sx={{
                                boxShadow: 'sm',
                                borderRadius: 'sm',
                                p: 2,
                            }}
                        >
                            <Typography
                                level="h4"
                                paddingBottom="15px"
                                justifyContent="center"
                                alignContent="center"
                            >
                                Available Chapters
                            </Typography>

                            <List
                                sx={{
                                    maxHeight: 230,
                                    minWidth: 350,
                                    maxWidth: 500,
                                    overflow: 'auto'
                                }}
                            >
                                {currentBook.chapters.map((chapter) => (
                                    <ListItem key={chapter._id}>
                                        <ListItemButton
                                            selected={selectedChapterID === chapter._id}
                                            onClick={() => handleChapterListItemClick(chapter._id)}
                                        >
                                            {chapter.title}
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Sheet>
                        <VoiceAndEnterKeySettings/>
                    </Body>
                    <ButtonRow>
                        <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                            <Button
                                sx={resizeButtonSX()}
                                onClick={
                                    () => {
                                        setNavigationPathToHomeIfNeeded(IDWasInURL);
                                        appDispatch(update(Page.HOME))
                                    }
                                }
                            >Cancel</Button>
                            <Button variant="solid" color="primary"
                                    sx={resizeButtonSX()}
                                    onClick={() => {
                                        if (selectedChapterID === '') {
                                            setShowSelectChapterSnackbarMsg(true)
                                            return
                                        }

                                        getChapter(
                                            {
                                                bookId: id ? id : currentBook._id!.toString(),
                                                chapterId: selectedChapterID
                                            }
                                        );
                                    }
                                    }>Go</Button>
                        </ButtonGroup>
                    </ButtonRow>
                </Sheet>
            }
            {
                (() => {
                    if (showSelectChapterSnackbarMsg)
                        return (
                            <Snackbar
                                size="lg"
                                variant="solid"
                                color={"warning"}
                                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                autoHideDuration={3000}
                                open={true}
                                onClose={() => {
                                    setShowSelectChapterSnackbarMsg(false);
                                }}
                                sx={{
                                    justifyContent: 'center',
                                    padding: '5px',
                                    whiteSpace: 'pre-line'
                                }}
                            >
                                You must Select the Chapter to Study
                            </Snackbar>
                        )
                })()
            }
        </>
    )
}
