import React, {FC, useEffect, useState} from 'react';
import Sheet from '@mui/joy/Sheet';
import Typography from "@mui/joy/Typography";
import {List, ListItem, ListItemButton, Snackbar} from "@mui/joy";
import {formatDate, newObjectId, popParagraphData} from "src/utils/functions";
import Button from "@mui/joy/Button";
import ButtonGroup from "@mui/joy/ButtonGroup";
import {Page, setAssessmentPageTitle, setAssessmentPageType, update} from "src/slices/pageSlice";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {resetSpellingAssessment} from "src/slices/spellingAssessmentSlice";
import {resizeButtonSX} from "src/utils/ui";
import {RootState} from "src/app/store";
import {
    saveSelectedSpellingInfo,
    setAssessmentCompletedBeforeStudying
} from "src/slices/wordsSlice";
import {ModTypes} from "src/utils/constants";
import {resetChapterAssessment} from "src/slices/chapterAssessmentSlice";
import {useParams} from 'react-router-dom';
import {
    getMod,
    startChapterTest,
    startSpellingTest,
    useLazyGetBookQuery,
    useLazyGetChapterQuery
} from "src/utils/api-service";
import {IBookMod, IBookModChapter, IMod, IModRecord} from "@backend/mongoose.gen";
import Title from "src/components/Shared/Title";
import Description from "src/components/Shared/Description";
import Body from "src/components/Shared/Body";
import ButtonRow from "src/components/Shared/ButtonRow";
import ModOrPageNotFound, {NotFoundType} from "src/components/Shared/ModOrPageNotFound";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import ObjectID from "bson-objectid";
import {saveChapterTestID, saveSelectedChapterInfo} from "src/slices/chapterSlice";
import {saveSpellingTestID} from "src/slices/wordsSlice";
import {VoiceAndEnterKeySettings} from "src/components/Shared/VoiceAndEnterKeySettings";
import {setCurrentBook} from "src/slices/bookSlice";
import {z} from "zod";
import {IBookModSchema} from "src/zodSchemas";

export const AssessmentHomePage: FC = () => {
    const [selectedChapterID, setSelectedChapterID] = useState('')
    const [showSelectChapterSnackbarMsg, setShowSelectChapterSnackbarMsg] = useState(false)

    const title = useAppSelector((state: RootState) => state.pages.assessmentPageTitle);
    const type = useAppSelector((state: RootState) => state.pages.assessmentPageType);
    const spellingID = useAppSelector((state: RootState) => state.words.spellingID);
    const currentBook = useAppSelector((state: RootState) => state.book.currentBook);

    const [IDWasInURL, setIDWasInURL] = useState(false)
    const [modRetrievalFailure, setModRetrievalFailure] = useState(false)

    const {id} = useParams();
    const appDispatch = useAppDispatch();
    const {setNavigationPathToHomeIfNeeded} = useSharedMgr();

    const [getChapter, {data: chapterData, fulfilledTimeStamp: chapterFulfilledTimeStamp}] = useLazyGetChapterQuery();
    const [getBook, {data: bookData, fulfilledTimeStamp: bookFulfilledTimeStamp}] = useLazyGetBookQuery();

    // Mod Data was retrieved via URL
    const modRetrieved = (modData: IBookMod | IMod) => {
        appDispatch(setAssessmentPageTitle(modData.title))
        appDispatch(setAssessmentPageType(modData.type))

        if (modData.type === ModTypes.spelling) {
            appDispatch(setAssessmentCompletedBeforeStudying(true))
            appDispatch(saveSelectedSpellingInfo({
                spellingID: modData._id.toString(),
                title: modData.title,
                words: "words" in modData ? modData.words : []
            }))
        } else if (modData.type === ModTypes.chapter) {
            popParagraphData(modData as IBookMod, appDispatch, true);
        }
    };

    const retrievalFailure = (error: string) => {
        console.log(`Error: ${error}`)
        setModRetrievalFailure(true)
    };

    useEffect(() => {
        setIDWasInURL(!!id)

        if (id) {
            if (window.location.href.includes('/test/book/id')) {
                // A Link to do a Book Test has been entered
                getBook(
                    {
                        bookId: id
                    }
                );
            } else {
                // A Link to Study a Spelling Test has been entered
                setModRetrievalFailure(false)
                getMod(id, modRetrieved, retrievalFailure)
            }
        }
    }, []);

    useEffect(() => {
        if (bookFulfilledTimeStamp) {
            const bookDataInstance: z.infer<typeof IBookModSchema> = {
                ...bookData?.data!,
                _id: undefined,
                stringId: id
            };

            appDispatch(setAssessmentPageTitle(bookDataInstance.title))
            appDispatch(setAssessmentPageType(bookDataInstance.type))
            appDispatch(setAssessmentCompletedBeforeStudying(true))
            appDispatch(setCurrentBook(bookDataInstance as z.infer<typeof IBookModSchema>))
        }
    }, [bookFulfilledTimeStamp]);

    useEffect(() => {
        if (chapterFulfilledTimeStamp) {
            const chapterDataInstance: IBookModChapter = chapterData?.data!;

            appDispatch(saveSelectedChapterInfo({
                chapterID: selectedChapterID,
                title: chapterDataInstance.title,
                paragraphs: chapterDataInstance.paragraphs,
                assessmentCompletedBeforeStudying: true
            }))

            appDispatch(resetChapterAssessment())
            setNavigationPathToHomeIfNeeded(IDWasInURL)
            appDispatch(update(Page.ANSWER_QUESTION))

            const chapterTestToStart: IModRecord = {
                // @ts-ignore
                _id: ObjectID(newObjectId()),
                // @ts-ignore
                mod_id: ObjectID(selectedChapterID)
            }

            startChapterTest(chapterTestToStart,
                (newChapterTestRecordID) => {
                    appDispatch(saveChapterTestID(newChapterTestRecordID))
                },
                (error: any) => console.error("Error:", error)
            )
        }
    }, [chapterFulfilledTimeStamp]);

    const handleChapterListItemClick = (index: string) => {
        setSelectedChapterID(index);
    };

    return (
        <>
            {
                (() => {
                    if (IDWasInURL && modRetrievalFailure) return (
                        <ModOrPageNotFound notFoundType={NotFoundType.Mod}/>
                    )

                    return (
                        <Sheet
                            sx={{
                                background: "linear-gradient(to right, #48BB78, #3B82F6)",
                                width: "100vw",
                                height: "100vh",
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <Title title={title}/>
                            <Description description={`Initial Assessment - ${formatDate(new Date())}`}/>
                            <Body boxGap={5}>
                                <Typography
                                    level="h2"
                                    fontSize="x-large"
                                    fontWeight="lg"
                                    sx={{
                                        color: "white",
                                        borderWidth: "2px",
                                        borderColor: "white",
                                        display: type === ModTypes.book ? "none" : "block"
                                    }}
                                    variant="outlined"
                                    padding="15px"
                                    borderRadius="md"
                                >
                                    {
                                        (() => {
                                            if (type === ModTypes.chapter) return (
                                                "Try to answer the questions correctly and quickly."
                                            )

                                            if (type === ModTypes.spelling) return (
                                                "Try to spell the words correctly and quickly."
                                            )
                                        })()
                                    }
                                </Typography>
                                {
                                    (() => {
                                        if (type === ModTypes.book) {
                                            return (
                                                <Sheet
                                                    variant="outlined"
                                                    sx={{
                                                        boxShadow: 'sm',
                                                        borderRadius: 'sm',
                                                        p: 2,
                                                    }}
                                                >
                                                    <Typography
                                                        level="h4"
                                                        paddingBottom="15px"
                                                    >
                                                        Choose the Chapter for Your Test
                                                    </Typography>

                                                    <List
                                                        sx={{
                                                            maxHeight: 230,
                                                            minWidth: 350,
                                                            maxWidth: 500,
                                                            overflow: 'auto'
                                                        }}
                                                    >
                                                        {currentBook.chapters.map((chapter) => (
                                                            <ListItem key={chapter._id}>
                                                                <ListItemButton
                                                                    selected={selectedChapterID === chapter._id}
                                                                    onClick={() => handleChapterListItemClick(chapter._id)}
                                                                >
                                                                    {chapter.title}
                                                                </ListItemButton>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Sheet>
                                            )
                                        }
                                    })()
                                }
                                <VoiceAndEnterKeySettings/>
                            </Body>
                            <ButtonRow>
                                <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                                    <Button
                                        sx={resizeButtonSX()}
                                        onClick={
                                            () => {
                                                setNavigationPathToHomeIfNeeded(IDWasInURL);
                                                appDispatch(update(Page.HOME))
                                            }
                                        }
                                    >Cancel</Button>
                                    <Button variant="solid" color="primary"
                                            sx={resizeButtonSX()}
                                            onClick={() => {
                                                if (type === ModTypes.spelling) {
                                                    setNavigationPathToHomeIfNeeded(IDWasInURL)
                                                    appDispatch(resetSpellingAssessment())
                                                    appDispatch(update(Page.SHOW_WORD))

                                                    const spellingTestToStart: IModRecord = {
                                                        // @ts-ignore
                                                        _id: ObjectID(newObjectId()),
                                                        // @ts-ignore
                                                        mod_id: ObjectID(spellingID)
                                                    }

                                                    startSpellingTest(spellingTestToStart,
                                                        (newSpellingTestRecordID) => {
                                                            appDispatch(saveSpellingTestID(newSpellingTestRecordID))
                                                        },
                                                        (error: any) => console.error("Error:", error)
                                                    )
                                                } else if (type === ModTypes.book) {
                                                    if (selectedChapterID === '') {
                                                        setShowSelectChapterSnackbarMsg(true)
                                                        return
                                                    }

                                                    getChapter(
                                                        {
                                                            bookId: id ? id : currentBook._id!.toString(),
                                                            chapterId: selectedChapterID
                                                        }
                                                    );
                                                }
                                            }
                                            }>Go</Button>
                                </ButtonGroup>
                            </ButtonRow>
                        </Sheet>
                    )
                })()
            }
            {
                (() => {
                    if (showSelectChapterSnackbarMsg)
                        return (
                            <Snackbar
                                size="lg"
                                variant="solid"
                                color={"warning"}
                                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                autoHideDuration={3000}
                                open={true}
                                onClose={() => {
                                    setShowSelectChapterSnackbarMsg(false);
                                }}
                                sx={{
                                    justifyContent: 'center',
                                    padding: '5px',
                                    whiteSpace: 'pre-line'
                                }}
                            >
                                You must Select a Chapter to Begin the Test
                            </Snackbar>
                        )
                })()
            }
        </>
    )
}