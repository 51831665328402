import React, {ChangeEvent, FC} from 'react';
import {Radio, RadioGroup, Sheet} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {
    updateVoiceGender,
    VoiceGender
} from "src/slices/wordsSlice";
import {saveSubmitWhenEnterKeyIsPressed} from "src/slices/settingsSlice";

export const VoiceAndEnterKeySettings: FC = () => {

    const voiceGender = useAppSelector((state: RootState) => state.words.voiceGender);
    const submitWhenEnterKeyIsPressed = useAppSelector((state: RootState) => state.settings.submitWhenEnterKeyIsPressed);

    const appDispatch = useAppDispatch();

    return (
        <>
            <Sheet
                variant="outlined"
                sx={{
                    boxShadow: 'sm',
                    borderRadius: 'sm',
                    p: 2,
                }}
            >
                <Typography
                    level="h4"
                    paddingBottom="15px"
                >
                    Spelling Voice Gender
                </Typography>
                <RadioGroup
                    size="lg"
                    value={voiceGender} name="radio-buttons-group"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const selectedValue = event.target.value as VoiceGender;
                        appDispatch(updateVoiceGender(selectedValue));
                    }}
                >
                    <Radio value="female" label="Female"/>
                    <Radio value="male" label="Male"/>
                </RadioGroup>
            </Sheet>
            <Sheet
                variant="outlined"
                sx={{
                    boxShadow: 'sm',
                    borderRadius: 'sm',
                    p: 2,
                }}
            >
                <Typography
                    level="h4"
                    paddingBottom="15px"
                >
                    Submit When 'Enter' Key Is Pressed
                </Typography>
                <RadioGroup
                    size="lg"
                    value={submitWhenEnterKeyIsPressed} name="radio-buttons-group"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        appDispatch(saveSubmitWhenEnterKeyIsPressed(event.target.value));
                    }}
                >
                    <Radio value="yes" label="Yes"/>
                    <Radio value="no" label="No"/>
                </RadioGroup>
            </Sheet>
        </>
    )
}
